import "styles/pages/page-broszury.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumbs from "components/Breadcrumbs";

const InfoMaterials = ({ pageContext }) => {
	const infoMaterials = pageContext.pageContent;
	return (
		<Layout headerColor="#9300B9">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/broszury-bg.jpg").default}
			/>
			<Breadcrumbs currentLocationLabel="Materiały informacyjne" />
			<section className="information">
				<div className="container">
					<h2 className="section-title">{pageContext.title}</h2>
					<div className="row">
						{infoMaterials.files?.map((node, index) => {
							return (
								<div
									className="col-xl-4 information__specific-column"
									key={index}
								>
									<div className="information__content-container">
										<div className="information__text-container">
											{node.title}
										</div>
										<div className="information__image-container">
											<img
												src={node.image?.sourceUrl}
												alt=""
												className="img-fluid"
											/>
											<a
												href={node.file?.mediaItemUrl}
												target="_blank"
												rel="noreferrer nofollow"
											>
												<button className="button information__button">
													Zobacz
												</button>
											</a>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default InfoMaterials;
